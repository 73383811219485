import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/about/Map.css';
import EMGAccordion from '../common/EMGAccordion';
import MapDot from './MapDot';

/**
 * Map component (with accordion details) for AboutUsPage
 */
class Map extends React.Component {
    
	/**
     * Constructor
     * @param {*} props Component props
     */
	constructor(props) {
		super(props);

		// Create a tab to store accordion categories React refs for activation on map pin click
		this.categoriesAccordions = [];

		for (let i = 0; i < this.props.categoriesData.length; i++) {
			this.categoriesAccordions.push(React.createRef());
		}

		// State initialisation
		this.state = {
			// Selected city on the map
			selectedCity: '',
			// Selected category selected on main accordion (offices / remote operation centers)
			mainAccordionSelectedKey: 0
		};

		this.onMainAccordionChange = this.onMainAccordionChange.bind(this);

		this.selectCity = this.selectCity.bind(this);
	}

	/**
     * Called on main accordion (category) change
     * @param {int} newKey Index of selected item on main accordion (category)
     */
	onMainAccordionChange(newKey) {
		// state update with selected city reset
		this.setState({
			mainAccordionSelectedKey: newKey,
			selectedCity: ''
		});

		// Expand first country on new category
		this.categoriesAccordions[this.state.mainAccordionSelectedKey].current.expand(0);
	}

	/**
     * Called on click on a map pin to select a city
     * @param {string} cityName Selected city name
     * @param {int} countryIndex Country index of the selected city
     */
	selectCity(cityName, countryIndex) {
		// Expand the good country
		this.categoriesAccordions[this.state.mainAccordionSelectedKey].current.expand(countryIndex);

		this.setState({
			selectedCity: cityName
		});
	}

	render() {
		// Categories accordion data construction
		let categoriesAccordionData = [];
		let categoriesPoints = {}; // key: accordion category id, value: point on the map (city node ?)
		let lineColors = [ 'emg-blue-background', 'emg-yellow-background' ];
		let dotColors = [ '#E4002B', '#0066CC' ];

		// for each category
		for (let i = 0; i < this.props.categoriesData.length; i++) {
			let category = this.props.categoriesData[i];
			let categoryPoints = [];

			let countryData = [];

			// Iterating on countries
			for (let indexCountry = 0; indexCountry < category.elements.countries.value.length; indexCountry++) {
				let country = category.elements.countries.value[indexCountry];
				let countryBodyElements = [];

				// Iterating on cities
				for (let indexCity = 0; indexCity < country.elements.cities.value.length; indexCity++) {
					let city = country.elements.cities.value[indexCity];
					let cityName = city.elements.name.value;

					let citySelectedClass = cityName === this.state.selectedCity ? 'map-city-selected' : '';

					// Iterating on companies
					for (let indexCompany = 0; indexCompany < city.elements.companies.value.length; indexCompany++) {
						let company = city.elements.companies.value[indexCompany];

						// Create text bloc (will be added to country accordion)
						let companyDiv = (
							<div className={citySelectedClass + ' map-companies'} key={'map-company-' + indexCity + '-' + indexCompany}>
								<div className="map-company-name">{company.elements.name.value}</div>
								<div className="map-company-city emg-text">{company.elements.city.value}</div>
							</div>
						);

						countryBodyElements.push(companyDiv);
					}

					// save point map
					let cityPoint = {
						name: cityName,
						country: country.elements.name.value,
						countryIndex: indexCountry,
						xMap: city.elements.map_x.value,
						yMap: city.elements.map_y.value
					};
					categoryPoints.push(cityPoint);
				}

				// Create HTML country node iterating on companies
				let countryBody = <div className="map-companies-container">{countryBodyElements.map((company) => company)}</div>;

				// Save the country item in tab
				let countryItem = {
					title: country.elements.name.value,
					body: countryBody
				};

				countryData.push(countryItem);
			}

			// Create Countries accordion
			let body = <EMGAccordion items={countryData} lineColor={lineColors[i]} ref={this.categoriesAccordions[i]} />;

			// Save countries accordion in the category tab
			let item = {
				title: "0"+(i+1)+". "+category.elements.title.value,
				body: body
			};

			categoriesPoints[i] = categoryPoints;

			categoriesAccordionData.push(item);
		}

		return (
			<Container fluid className="map-container">
				<Row>
					<Col xs={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} className="map-accordion-container">
						<EMGAccordion items={categoriesAccordionData} lineColor={lineColors} onChange={this.onMainAccordionChange} />
					</Col>
					<Col xs={{ span: 6 }} className="map-background-col">
						<div className="hidden-small-version map-background">
							<img className="map-background-img" src={this.props.background} alt="europe map" />
							{// Create map pins for each city of selected category
							categoriesPoints[this.state.mainAccordionSelectedKey].map((point, index) => (
								<MapDot
									x={point.xMap}
									y={point.yMap}
									color={dotColors[this.state.mainAccordionSelectedKey]}
									className={point.name === this.state.selectedCity ? 'map-city-selected' : ''}
									onClick={(e) => this.selectCity(point.name, point.countryIndex)}
									key={'map-dot-city-' + index}
								/>
							))}
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Map;
