import React from 'react';
import '../../css/common/SectionCorner.css';

/**
 * Colored corner background component
 */
class SectionCorner extends React.Component {

	render() {
		let triangle = '';

		let fill = '#141417';

		if (this.props.fillColor) {
			fill = this.props.fillColor;
		}

        // Position the corner depending on position prop
		if (this.props.position === 'top') {
			triangle = (
				<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
					<polygon points="0,0 100,100 100,0" fill={fill} />
				</svg>
			);
		}
		else if (this.props.position === 'bottom') {
			triangle = (
				<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
					<polygon points="0,100 0,50 100,0 100,100" fill={fill} />
				</svg>
			);
		}
		else if (this.props.position === 'topleft') {
			triangle = (
				<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
					<polygon points="0,0 0,100 100,0" fill={fill} />
				</svg>
			);
		}

		return (
			<div className={this.props.className + ' section-corner'}>
				<div className={'section-corner-' + this.props.position}>{triangle}</div>
			</div>
		);
	}
}

export default SectionCorner;
