import React from 'react';
import '../../css/about/Timeline.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/**
 * Timeline component for AboutUs page
 */
class Timeline extends React.Component {

    /**
     * Constructor
     * @param {*} props Component props
     */
	constructor(props) {

		super(props);

        // Create refs
		this.carousel = React.createRef();

		this.bottomLine = React.createRef();

        // Init state
		this.state = {
            // is carousel previous button active
            isPrevActive: false,
            // is carousel next button active
            isNextActive: true,
            // carousel current index (first element visible)
            currentIndex: 0,
            // nb of items displayed in carousel (at a same time)
            itemsInSlide: 4,
            // width of dotted line (veeeery but calculated after mount)
			bottomLineWidth: 15000
		};

		this.previous = this.previous.bind(this);

		this.next = this.next.bind(this);

		this.onResize = this.onResize.bind(this);

		this.checkWidth = this.checkWidth.bind(this);

        this.onSlideChanged = this.onSlideChanged.bind(this);
        
	}

	componentDidMount() {
        // Wait 200ms before updating dotted line width (if not, width and offset not well calculated)
		setTimeout(this.checkWidth, 200);
	}

    /**
     * Update bottom dotted line width based on elements added to timeline
     */
	checkWidth() {
        // get first element in carousel
        let firstElement = document.getElementById('timeline-date-0');
        
        // calculate total width based on element width
		let fullWidth = (firstElement.offsetWidth) * (this.props.dates.length - 1);

        // if needed, update state
		if (fullWidth !== 0 && fullWidth !== this.state.bottomLineWidth) {
			this.setState({ bottomLineWidth: fullWidth });
		}
	}

    /**
     * Previous on carousel
     */
	previous() {
		if (this.state.isPrevActive) {
			let decrement = this.state.itemsInSlide;

			if (this.state.currentIndex - this.state.itemsInSlide < 0) {
				decrement = this.state.currentIndex;
			}

			this.carousel.current.slideTo(this.state.currentIndex - decrement);

			if (this.state.currentIndex - decrement > 0) {
				this.setState({
					isPrevActive: true
				});
			}
			else {
				this.setState({
					isPrevActive: false
				});
			}

			this.setState({
				isNextActive: true
			});

			this.setState({
				currentIndex: this.state.currentIndex - decrement
			});
		}
	}

    /**
     * Next on carousel
     */
	next() {
		if (this.state.isNextActive) {
			let increment = this.state.itemsInSlide;

			if (this.state.currentIndex + this.state.itemsInSlide + this.state.itemsInSlide >= this.props.dates.length) {
				increment = this.props.dates.length - this.state.currentIndex - this.state.itemsInSlide;
			}

			this.carousel.current.slideTo(this.state.currentIndex + increment);

			if (this.state.currentIndex + increment + this.state.itemsInSlide < this.props.dates.length) {
				this.setState({
					isNextActive: true
				});
			}
			else {
				this.setState({
					isNextActive: false
				});
			}

			this.setState({
				isPrevActive: true
			});

			this.setState({
				currentIndex: this.state.currentIndex + increment
			});
		}

	}

    /**
     * Called on carousel resize (to update nbItemsDisplayed, next and previous button visibility, etc)
     * @param {*} event Resize event
     */
	onResize(event) {

        // Update isPrevActive and isNextActive
		let { itemsInSlide, item } = event;
		let prevActive = item > 0;
		let nextActive = item + itemsInSlide < this.props.dates.length;

		if (item + itemsInSlide >= this.props.dates.length) {
			item = this.props.dates.length - itemsInSlide;

			prevActive = item > 0;
			nextActive = false;
		}

		let newState = {
			itemsInSlide: itemsInSlide,
			currentIndex: item,
			isNextActive: nextActive,
			isPrevActive: prevActive
        };
        
        // Update bottom dotted line width if necessary
        let firstElement = document.getElementById('timeline-date-0');
		let fullWidth = firstElement.offsetWidth * (this.props.dates.length - 1);

		if (fullWidth !== 0) {
			newState.bottomLineWidth = fullWidth;
		}

		this.setState(newState);
	}

    /**
     * Called on slide changed (to update state)
     * @param {*} event Carousel event
     */
	onSlideChanged(event) {
        // Update state
        this.setState({ currentIndex: event.item });
        
        // update prev and next button visibility
		this.onResize(event);
	}

	render() {

        // nb items displayed into carousel based on window width
		let responsive = {
			0: {
				items: 1
			},
			992: {
				items: 2
			},
			1350: {
				items: 3
			},
			1750: {
				items: 4
			}
		};


        // Enable or disable next button by css based on state
		let nextButtonClass = '';
		if (!this.state.isNextActive) {
			nextButtonClass = ' arrow-disabled';
		}

        // Enable or disable prev button by css based on state
		let prevButtonClass = '';
		if (!this.state.isPrevActive) {
			prevButtonClass = ' arrow-disabled';
		}

		return (
			<Container fluid>
				<React.Fragment>
					<style>
						{`
                        #timeline-bottom-line {
                            width: ${this.state.bottomLineWidth}px !important;
                        }
                    `}
					</style>
				</React.Fragment>
				<Row>
					<Col xs={{ span: 10, offset: 1 }} className="timeline-controls-col">
						<div className="timeline-controls-container">
							<div className={'timeline-arrow' + prevButtonClass} onClick={this.previous}>
								<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 51.326 51.326">
									<path
										className="timeline-arrow-svg"
										fill="none"
										stroke="#fff"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="5px"
										d="M0 30.213l24.08-6.133L30.213 0"
										opacity="1.0"
										transform="rotate(135 18.199 22.571)"
									/>
								</svg>
							</div>
							<div className="timeline-controls-separator" />
							<div className={'timeline-arrow' + nextButtonClass} onClick={this.next}>
								<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 51.326 51.326">
									<path
										className="timeline-arrow-svg"
										fill="none"
										stroke="#ffffff"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="5px"
										d="M0 30.213l24.08-6.133L30.213 0"
										transform="rotate(-45 33.127 7.642)"
									/>
								</svg>
							</div>
						</div>
					</Col>
				</Row>
				<Row className="timeline">
					<AliceCarousel
						className=""
						startIndex={this.state.currentIndex}
						infinite={false}
						duration={500}
						dotsDisabled={true}
						buttonsDisabled={true}
						responsive={responsive}
						ref={this.carousel}
						onInitialized={this.onResize}
						onResized={this.onResize}
						onSlideChanged={this.onSlideChanged}>
						{this.props.dates.map((date, index) => {
							let bottomLine = index === 0 ? <div id="timeline-bottom-line" /> : '';
							return (
								<div key={'timeline-date-' + index} id={'timeline-date-' + index} className="timeline-date-bloc">
									{bottomLine}
									<div className="timeline-date-bloc-container">
										<div className="timeline-date-blue-dot" />
										<div className="timeline-date-year">{date.year}</div>
										<div className="timeline-date-text" dangerouslySetInnerHTML={{ __html: date.text }} />
									</div>
								</div>
							);
						})}
					</AliceCarousel>
					<div className="timeline-small-mask-start" />
					<div className="timeline-small-mask" />
				</Row>
			</Container>
		);
	}
}

export default Timeline;
