import React from 'react';
import { graphql, Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/Main.css';
import TitleSection from '../components/home/TitleSection';
import MainLayout from '../components/MainLayout';
import '../css/AboutUs.css';
import SectionCorner from '../components/common/SectionCorner';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Container from 'react-bootstrap/Container';
import Timeline from '../components/about/Timeline';
import EMGAccordion from '../components/common/EMGAccordion';
import Map from '../components/about/Map';

/**
 * Aboutus page
 */
class AboutUs extends React.Component {

  	/**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {
		super(props);

		// Ref to parners carousel
		this.carousel = React.createRef();

		this.state = {
			// is partners carousel previous button active
			isPrevActive: false,
			// is partners carousel next button active
			isNextActive: true,
			// partners carousel current index
			currentIndex: 0,
			// partners carousel current number of logos in a slide
			itemsInSlide: 5,
			// is small version (small screen)
			smallVersion: false
		};

		this.onResize = this.onResize.bind(this);

		this.setNewState = this.setNewState.bind(this);

		this.teamAccordion = React.createRef();
	}

	/**
	 * On partner carousel resize : update state (prevActive, nextActive, nbItemsPerSlide...)
	 * @param {CarouselEvent} event CarouselEvent
	 */
	onResize(event) {
		let { itemsInSlide, item } = event;
		let prevActive = item > 0;
		let nextActive = item + itemsInSlide < this.props.data.allKontentItemAboutuspage.nodes[0].elements.partners?.value.length;

		if (item + itemsInSlide >= this.props.data.allKontentItemAboutuspage.nodes[0].elements.partners?.value.length) {
			item = this.props.data.allKontentItemAboutuspage.nodes[0].elements.partners?.value.length - itemsInSlide;

			prevActive = item > 0;
			nextActive = false;
		}

		this.setState({
			itemsInSlide: itemsInSlide,
			currentIndex: item,
			isNextActive: nextActive,
			isPrevActive: prevActive
		});
	}

	/**
	 * Set new state and adapt team accordion
	 * @param {*} newState State to apply
	 */
	setNewState(newState) {
		if (this.teamAccordion.current) {
			if (newState.smallVersion && !this.state.smallVersion) {
				this.teamAccordion.current.expand(-1);
			}
			else if (!newState.smallVersion && this.state.smallVersion) {
				this.teamAccordion.current.expand(0);
			}

			this.setState({
				smallVersion: newState.smallVersion
			});
		}
	}

	render() {
		// Partners carousel nb of items per slide depending on screen width
		let responsive = {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			1024: {
				items: 3
			},
			1350: {
				items: 4
			},
			1750: {
				items: 5
			}
		};

		let data = this.props.data.allKontentItemAboutuspage.nodes[0].elements;

		// Get timeline data
		let timelineTab = [];

		for (let i = 0; i < data.timeline.value.length; i++) {
			let date = data.timeline.value[i];
			let dateObject = {
				year: date.elements.year.value,
				text: date.elements.text.value
			};
			timelineTab.push(dateObject);
		}

		// Team accordion data construction
		let teamAccordionData = [];

		// Categories loop
		for (let i = 0; i < data.team_categories.value.length; i++) {
			let category = data.team_categories.value[i];

			// Members loop
			let body = category.elements.members.value.map((member, indexMember) => (
				<Link to={member.elements.id.value} key={'team-member-' + i + '-' + indexMember} className="about-team-member">
					<div className="about-team-member-container">
						<div className="about-team-member-picture-container">
							<img className="about-team-member-picture" src={member.elements.picture.value[0].url} alt={member.elements.name.value} />
						</div>
						<div className="about-team-member-name">{member.elements.name.value}</div>
						<div className="emg-text">{member.elements.role.value}</div>
					</div>
				</Link>
			));

			let item = {
				title: category.elements.title.value,
				body: body
			};

			teamAccordionData.push(item);
		}

		return (
			<MainLayout
				setNewState={this.setNewState}
				activeItem={0}
				scrollSnap={false}
				className="outsidebroadcast-container"
				footerClass="footer-dark">
				{/* Title section */}
				<Row className="nearly-fullheight fullheight">
					<TitleSection
						background={data.background.value[0].url}
						title={data.title.value}
						text={data.subtitle.value}
						lineColor="emg-blue"
					/>
				</Row>
				{/* Paragraphs section */}
				<Row className="about-paragraphs-line">
					<Col xs={{ offset: 1, span: 10 }} className="d-flex about-paragraphs">
						<Container fluid>
							<Row>
								{data.paragraphs.value.map((paragraph, index) => (
									<Col xs="12" sm="6" lg="4" key={'about-par-' + index} className="about-paragraph">
										<div className="emg-title">{paragraph.elements.title.value}</div>
										<div className="emg-text" dangerouslySetInnerHTML={{ __html: paragraph.elements.text.value }} />
									</Col>
								))}
							</Row>
						</Container>
					</Col>
					<SectionCorner position="bottom" />
				</Row>
				{/* Timeline section */}
				<Row className="about-timeline-title">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{data.timeline_title.value}</div>
					</Col>
				</Row>
				<Row className="about-timeline">
					<Col xs={{ offset: 0, span: 12 }}>
						<Timeline dates={timelineTab} />
					</Col>
				</Row>
				{/* Team section */}
				<Row className="about-timeline-title">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{data.team_title.value}</div>
					</Col>
				</Row>
				<Row className="about-team" id="about-team">
					<Col xs={{ offset: 1, span: 10 }}>
						<EMGAccordion items={teamAccordionData} ref={this.teamAccordion} />
					</Col>
				</Row>
				{/* Map section */}
				<Row className="about-map">
					<Map background={data.map_background.value[0].url} categoriesData={data.map_categories.value} />
				</Row>
				{/* ESG section */}
				<Row className="">
					<TitleSection
						background={data.esg_background.value[0].url}
						title={data.esg_title.value}
						text={data.esg_text.value}
						lineColor="emg-blue"
						fullwidth={true}
					/>
				</Row>
				{/* Partners section
				<Row className="about-partners-title" id="partners">
					<SectionCorner position="topleft" />
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{data.partners_title?.value}</div>
					</Col>
				</Row>
				<Row className="about-partners-carousel">
					<Col xs={{ span: 1, offset: 0 }} className="testimonialsSection-arrow-col">
						//<div className={"testimonialsSection-arrow-left"+prevButtonClass} onClick={this.previous} />
					</Col>
					<Col xs={{ span: 10, offset: 0 }} className="testimonialsSection-Carousel">
						<AliceCarousel
							className="testimonialsSection-carousel"
							autoPlay={true}
							autoHeight={false}
							startIndex={this.state.currentIndex}
							infinite={true}
							duration={1000}
							autoPlayInterval={4000}
							dotsDisabled={false}
							buttonsDisabled={true}
							responsive={responsive}
							ref={this.carousel}
							onInitialized={this.onResize}
							onResized={this.onResize}>
							{data.partners?.value.map((partner, index) => (
								<div key={'partner-' + index} className="about-partners-logo">
									<a href={partner?.elements.link.value} className="about-partners-logo-background">
										<img src={partner?.elements.logo.value[0].url} alt="partner-logo" />
									</a>
								</div>
							))}
						</AliceCarousel>
					</Col>
					<Col xs={{ span: 1, offset: 0 }} className="testimonialsSection-arrow-col">
						//<div className={"testimonialsSection-arrow-right"+nextButtonClass} onClick={this.next}/>
					</Col>
				</Row>*/}
			</MainLayout>
		);
	}
}

export default AboutUs;

export const query = graphql`
	query AboutUs {
		allKontentItemAboutuspage {
			nodes {
				elements {
					background {
						value {
							url
						}
					}
					title {
						value
					}
					subtitle {
						value
					}
					paragraphs {
						value {
							... on kontent_item_simpleparagraph {
								elements {
									title {
										value
									}
									text {
										value
									}
								}
							}
						}
					}
					team_title {
						value
					}
					team_categories {
						value {
							... on kontent_item_teamcategory {
								elements {
									title {
										value
									}
									members {
										value {
											... on kontent_item_teammember {
												elements {
													details {
														value
													}
													name {
														value
													}
													picture {
														value {
															url
														}
													}
													role {
														value
													}
													id {
														value
													}
												}
											}
										}
									}
								}
							}
						}
					}
					timeline_title {
						value
					}
					timeline {
						value {
							... on kontent_item_timelinedate {
								elements {
									text {
										value
									}
									year {
										value
									}
								}
							}
						}
					}
					map_background {
						value {
							url
						}
					}
					map_categories {
						value {
							... on kontent_item_mapcategory {
								elements {
									countries {
										value {
											... on kontent_item_mapcountry {
												elements {
													name {
														value
													}
													cities {
														value {
															... on kontent_item_mapcity {
																elements {
																	name {
																		value
																	}
																	companies {
																		value {
																			... on kontent_item_mapcompany {
																				elements {
																					name {
																						value
																					}
																					city {
																						value
																					}
																				}
																			}
																		}
																	}
																	map_x {
																		value
																	}
																	map_y {
																		value
																	}
																}
															}
														}
													}
												}
											}
										}
									}
									title {
										value
									}
								}
							}
						}
					}
					esg_title {
						value
					}
					esg_text {
						value
					}
					esg_background {
						value {
							url
						}
					}

				}
			}
		}
	}
`;
