import React from 'react';

/**
 * Map dot (or pîn if selected) component for AboutUsPage's Map 
 */
class MapDot extends React.Component {

	render() {
		return (
			<div
				className={this.props.className + ' map-dot-container'}
				style={{ left: this.props.x + '%', top: this.props.y + '%' }}
				onClick={this.props.onClick}>
				{/* Map dot (if not selected - depending on css class given in props) */}
				<div className="map-dot" style={{ borderColor: this.props.color }}>
					{/*<div className="map-dot-center" style={{ backgroundColor: this.props.color }} />*/}
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
						<g transform="translate(914 4903) rotate(180)">
							<g transform="translate(893 4882)" fill="none" stroke={this.props.color} strokeWidth="2">
								<circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
								<circle cx="10.5" cy="10.5" r="9.5" fill="none" />
							</g>
							<circle cx="4.5" cy="4.5" r="4.5" transform="translate(899 4888)" fill={this.props.color} />
						</g>
					</svg>
				</div>

				{/* Map pin (if selected - depending on css class given in props) */}
				<div className="map-pin">
					<svg xmlns="http://www.w3.org/2000/svg" width="25.698" height="39" viewBox="0 0 25.698 39">
						<path
							id="map-icon-pin-svg"
							fill={this.props.color}
							d="M-275.591 375.019c-1.9-3.069-3.843-5.881-5.444-8.876a117.316 117.316 0 0 1-6.519-13.317c-2.72-7.143 1.567-14.6 8.987-16.432a12.866 12.866 0 0 1 15.753 10.441 13.085 13.085 0 0 1-.936 6.881c-2.609 6.718-6.365 12.815-10.217 18.86-.475.749-.981 1.478-1.624 2.443zm5.421-26.463a5.394 5.394 0 0 0-5.419-5.388 5.4 5.4 0 0 0-5.311 5.39 5.415 5.415 0 0 0 5.415 5.412 5.437 5.437 0 0 0 5.315-5.414z"
							transform="translate(288.376 -336.019)"
						/>
					</svg>
				</div>
			</div>
		);
	}
}

export default MapDot;
